import { API } from 'utils/axios';

export const getRoles = async ({ businessId }) => {
	const command = 'get_business_user_roles';
	const data = {
		cmd: command,
		business_id: businessId,
	};

	return await API({ data });
};
export const getRole = async ({ uid }) => {
	const command = 'get_user_role';
	const data = {
		cmd: command,
		uid
	};

	return await API({ data });
};
export const updateRole = async ({ roleId, businessId, title, permissions }) => {
	const command = 'update_account_role';
	const data = {
		cmd: command,
		dbofields: {
			title,
			business_id: businessId
		},
		permissions,
		obj_id: roleId
	};

	return await API({ data });
};
export const updateUserRole = async ({ uid, role_id }) => {
	const command = 'update_user_role';
	const data = {
		cmd: command,
		uid,
		role_id,
	};

	return await API({ data });
};
export const createRole = async ({ businessId, title, permissions }) => {
	const command = 'create_account_role';
	const data = {
		cmd: command,
		dbofields: {
			title,
			business_id: businessId
		},
		permissions,
		obj_id: -1
	};

	return await API({ data });
};

export const deleteRole = async ({ id }) => {
	const command = 'delete_account_role';
	const data = {
		cmd: command,
		role_id: id,
	};

	return await API({ data });
};
