import './app-config'
import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { MantineProvider } from '@mantine/core';
import AppWrapper from './AppWrapper';
// import reportWebVitals from './reportWebVitals';
const Contexts = lazy(() => import('contexts'));
const root = createRoot(document.getElementById('root')! as HTMLElement);
root.render(
	<React.Fragment>
		<Contexts>
			<MantineProvider withGlobalStyles withNormalizeCSS>
				<AppWrapper />
			</MantineProvider>
		</Contexts>
	</React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
