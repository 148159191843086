export const EVENT_CATEGORIES = [
	{
		name: 'music',
		label: 'MUSIC',
		id: 1
	},
	{
		name: 'arts',
		label: 'ARTS',
		id: 2
	},
	{
		name: 'film-n-theatre',
		label: 'FILM & THEATRE',
		id: 3
	},

	{
		name: 'food-n-drink',
		label: 'FOOD & DRINK',
		id: 4
	},
	{
		name: 'festivals',
		label: 'FESTIVALS',
		id: 5
	},
	{
		name: 'spoken',
		label: 'SPOKEN',
		id: 6
	},
	{
		name: 'sports',
		label: 'SPORTS',
		id: 7
	},
	{
		name: 'exhibitions',
		label: 'EXHIBITIONS',
		id: 8
	},
	{
		name: 'kids-activities',
		label: 'KIDS ACTIVITIES',
		id: 9
	},
	{
		name: 'markets',
		label: 'MARKETS',
		id: 10
	},
];

export const EVENT_ADDITIONAL_INFO_ANSWER_TYPES = [
	{
		name: "checkboxes",
		value: "Checkboxes",
		isOptions: true,
	},
	{
		name: "text-field",
		value: "Text Field",
		isOptions: false,
	},
	{
		name: "selection-dropdown",
		value: "Selection Dropdown",
		isOptions: true,
	},
	{
		name: "radio-button",
		value: "Radio Button",
		isOptions: true,
	},
]

export const FREE_REDEMPTION = "free_redemption"
export const DISCOUNT_PERCENT = "discount_percent"
export const DISCOUNT_COST = "discount_cost"

export const DISCOUNT_TYPES = [
	{
		name: FREE_REDEMPTION,
		value: "Free Redemption"
	},
	{
		name: DISCOUNT_PERCENT,
		value: "Discount by Percent",
	},
	{
		name: DISCOUNT_COST,
		value: "Discount by Cost"
	},
]

export const EVENT_STATUS = [
	{
		name: "published",
		value: 0,
		label: "Published"
	},
	{
		name: "hidden",
		value: 1,
		label: "Hidden"
	},
	{
		name: "unpublished",
		value: -1,
		label: "Unpublished"
	},
]
export const EVENT_EXPIRED_STATUS = {
	name: "expired",
	value: -2,
	label: "Expired"
}