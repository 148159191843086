import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';

type Store = {
	isLoading: boolean;
	isShowNavBar: boolean;
	activeTab: number,

	setState: (key: string, value: any) => void;
};
const initialState = {
	isLoading: false,
	isShowNavBar: false,
	activeTab: 0,
};

const useGlobalStore = create<Store>()(
	devtools(
		persist(
			(set, get) => ({
				...initialState,
				setState: (key, value) => {
					console.log(key, value);
					set((s) => ({
						...s,
						[key]: value,
					}));
				},
			}),
			{
				name: 'wo-global-store',
				version: 1,
			}
		)
	)
);
export default useGlobalStore;
