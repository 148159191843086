import { IconCheck, IconX } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';

export const NotifySuccess = ({
    title,
    message
}) => {
    notifications.show({
        title,
        message,
        icon: <IconCheck />,
        color: 'teal',
    })

}
export const NotifyError = ({
    title,
    message
},
) => {

    notifications.show({
        title,
        message,
        icon: <IconX />,
        color: 'red'
    })
}