import React, { memo, lazy, useTransition, useContext, useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './App.module.scss';
import { AppShell, Navbar, Box, Flex } from '@mantine/core';
import Routes from './routes/routes';
import { NO_NAVBAR_ROUTES, TOP_NAV_BAR_SCREEN_SIZE } from 'utils/constants/core';
import { useViewportSize } from '@mantine/hooks';
import useGlobalStore from 'stores/GlobalStore';
import { useMediaQuery } from '@mantine/hooks';
import { BusinessVenuesContext } from 'contexts/BusinessVenuesProvider';
import * as RoleAPI from "services/role";
import useUserStore from 'stores/UserStore';
import useAppNavigate from "hooks/useAppNavigate";

const FloatingButton = lazy(() => import('atoms/Buttons/FloatingButton'));
const SignupNavBar = lazy(() => import('atoms/NavBars/SignupNavBar'));
const AppCoreNavBar = lazy(() => import('atoms/NavBars/AppCoreNavBar'));
const TopNavBar = lazy(() => import('atoms/NavBars/TopNavBar'));

function App() {
	const isMounted = useRef(false);
	const [isShowNav, setIsShowNav] = useState(false);
	const location = useLocation();
	const navigate = useAppNavigate();
	const [isPending, startTransition] = useTransition();
	const isTopNavbarScreenSize = useMediaQuery(`(max-width: ${TOP_NAV_BAR_SCREEN_SIZE})`);
	const isMobile = useMediaQuery(`(max-width: 700px)`);
	const UserStore = useUserStore();
	const GlobalStore = useGlobalStore();

	// const { height: windowHeight, width: windowWidth } = useViewportSize();
	const onToggleNavBar = (force = false, forceState = true) => {
		startTransition(() => {
			const isOpen = force == true ? forceState : !isShowNav;
			// setAppData('navbar', { show: isOpen });
			setIsShowNav(isOpen);
		});
	};
	// useEffect(() => {
	// 	if (UserStore?.user?.id) {
	// 		const getRole = async () => {
	// 			const { data } = await RoleAPI.getRole({ uid: UserStore.user.id })
	// 			const newUser = { ...UserStore?.user }
	// 			const userRole = data.data.role
	// 			newUser['role_details'] = userRole
	// 			UserStore.setState('user', newUser);
	// 			const [_, rootPath] = location.pathname.split('/')
	// 			if (!userRole.permissions?.includes(`/${rootPath}`)) {
	// 				navigate(userRole[0]);
	// 			}

	// 		}
	// 		getRole();
	// 	}

	// }, [UserStore.user.id])



	useEffect(() => {
		if (NO_NAVBAR_ROUTES.includes(location.pathname)) {
			setIsShowNav(false);
		} else {
			setIsShowNav(true);
		}
	}, [location.pathname])
	// const { innerWidth: windowWidth, innerHeight: windowHeight } = window;

	// useEffect(() => {
	// 	if (window.innerWidth > 800) {
	// 		onToggleNavBar(true);
	// 	} else {
	// 		onToggleNavBar(true, false);
	// 	}
	// }, [windowWidth]);
	const NavBar = () => {
		if (NO_NAVBAR_ROUTES.includes(location.pathname)) return false
		if (isShowNav) {
			// if (navbar.show) {
			switch (location.pathname) {
				case '/signup':
					return <SignupNavBar />;
					break;

				default:
					return <AppCoreNavBar />;
					break;
			}
		}
		return false;
	};

	return (
		<div className={styles['app']} >
			<AppShell
				navbarOffsetBreakpoint="lg"
				asideOffsetBreakpoint="lg"
				navbar={isShowNav && !isTopNavbarScreenSize ? <NavBar /> : false}
				header={isShowNav && isTopNavbarScreenSize ? <TopNavBar /> : false}
				style={{ position: 'relative' }}
				classNames={isMobile && {
					main: styles['app-shell-main']
				}}
			>
				<Box
					p={(NO_NAVBAR_ROUTES.includes(location.pathname)) ? 0 : {
						lg: '50px 30px 50px 30px',
						md: '0px 10px 50px 20px',
						sm: '0px 10px 50px 10px',
						xs: '0px 10px 50px 10px'
					}}
					w={'100%'}
					h={'100%'}
				>
					<Routes />
				</Box>
				{/* {
					!NO_NAVBAR_ROUTES.includes(location.pathname) &&
					<>
						<Box display={{ xs: "block", lg: "none" }}>
							<FloatingButton onClick={() => onToggleNavBar()} />
						</Box>
					</>
				} */}
			</AppShell>
		</div >
	);
}

export default App;
