import axios from 'axios';
import { BASE_API_URL } from 'utils/constants/core.js';
import { NotifyError } from 'utils/notifications';

export const API = axios.create({
	baseURL: BASE_API_URL,
	timeout: 50000,
	headers: { 'Content-Type': 'multipart/form-data' },
	method: 'post',
});

//on Sending Request
API.interceptors.request.use(
	function (request) {
		const bodyFormData = new FormData();
		bodyFormData.append('json', JSON.stringify(request.data));
		console.log("------ sending request --------");

		for (const [key, value] of Object.entries(request.data)) {
			console.log(key, ":", value)
			if (value instanceof File) {
				bodyFormData.append(key, value);
			}
		}
		console.log("---------------------");
		request.data = bodyFormData;
		// const cancelToken = axios.CancelToken;
		// const source = cancelToken.source();
		// request.cancelToken = source.token;



		return request;
	},
	function (error) {
		NotifyError({ title: error.code, message: error.message });
	}
);

//on Receiving Response
API.interceptors.response.use(
	function (response) {
		// Do something with response data
		console.log('res', response);
		return response;
	},
	function (error) {
		console.log('err', error);

		NotifyError({ title: error.code, message: error.message });
		return {
			...error,
			data: {
				status: false
			}
		}
	}
);
