

export const SERVER_URL = window.bookmy.server_url;
export const WO_WEB_URL = window.bookmy.wo_web_url;
console.log("server url", SERVER_URL)
// export const SERVER_URL =
// 	'http://bookmy.project8.inscribecms.com/';
// 'https://demo.bookmy.com.au/';

export const BASE_API_URL = `${SERVER_URL}venue_admin_WO/ajax.php`;
// export const BASE_API_URL = window.bookmy.url + "ajax.php";

export const DEFAULT_USER_META = {
	ap: 'besc',
	os: 2,
	av: '1.0.0',
	u: 0,
	d: 0,
	i: 'web',
};

export const APP_CODE = 'WO';

export const STRIPE_SCRIPT_URL = 'https://js.stripe.com/v3/';

export const NO_NAVBAR_ROUTES = ['/login', '/resetpassword'];
export const BACK_NAV_ROUTES = [
	{
		route: '/events/',
	},
];
export const APP_PLATFORMS = [
	{
		name: 'wo-web-admin',
		value: "What's On Web Admin",
		title: "What's On Web Admin",
	},
	{
		name: 'wo-app',
		value: "What's On Mobile App",
		title: "What's On Mobile App",
	},
	{
		name: 'wo-web-ticket-purchase',
		value: "What's On Web Ticket Purchase",
		title: "What's On Web Ticket Purchase",
	},
];
export const USER_GENDERS = [
	{
		name: "Male",
		value: "Male"
	},
	{
		name: "Female",
		value: "Female"
	},
	{
		name: "Other",
		value: "Other"
	}
]
export const FEEDBACK_CATEGORIES = [
	{
		name: 'feedback-improvement',
		value: 'Improvement',
	},
	{
		name: 'feedback-features',
		value: 'Features',
	},
	{
		name: 'feedback-bug-crash',
		value: 'Bug/Crash',
	},
];

//all permissions available
export const USER_PERMISSIONS = [
	{
		name: 'checkin-app',
		label: 'CheckIn App',
	},
	{
		name: 'events',
		label: 'Events',
	},
	{
		name: 'accounts',
		label: 'Accounts',
	},
	{
		name: 'settings',
		label: 'Settings',
	},
	{
		name: 'support',
		label: 'Support',
	},
	{
		name: 'resources',
		label: 'Resources',
	},
	{
		name: 'advertisements',
		label: 'Advertisements',
	},
];


export const APP_NAV_SECTIONS = [
	{
		label: 'Events',
		route: '/events',
		permissions: ['events'],
	},
	{
		label: 'Accounts',
		route: '/accounts',
		permissions: ['accounts'],
	},
	{
		label: 'Settings',
		route: '/settings',
		permissions: ['settings'],
	},
	{
		label: 'Support',
		route: '/support',
		permissions: ['support'],
	},
	{
		label: 'Resources',
		route: '/resources',
		permissions: ['resources'],
	},
	{
		label: 'Advertisements',
		route: '/advertisements',
		permissions: ['advertisements'],
	},
];

export const TOP_NAV_BAR_SCREEN_SIZE = '1200px'