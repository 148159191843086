import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { LocalStorage } from 'utils/localStorage';
import * as AdsAPI from 'services/advertisements';
import useUserStore from './UserStore';
//ts-
const STORE_KEY = 'wo-advertisements-store';

interface Advertisement {
	id?: string | number;
	[key: string | number]: any;
}
type Store = {
	advertisements: Advertisement[];
	loadAdvertisements: ({ businessId }: { businessId: number | string }) => void;
	setState: (key: string, value: any) => void;
	updateAdvertisement: (id: string | number, value: any) => void;
	addAdvertisement: (value: Advertisement) => void;
	getAdvertisement: (id: string | number) => Advertisement;
	deleteAdvertisement: (id: string | number) => void;
	resetState: () => void;
};
let initialState = {
	advertisements: [],
};
// @ts-ignore
// initialState = LocalStorage.initStore(STORE_KEY, initialState)

const useAdvertismentsStore = create<Store>()(
	// devtools(
	//     persist(
	(set, get) => ({
		...initialState,
		loadAdvertisements: async ({ businessId }) => {
			const { data } = await AdsAPI.getAdvertisements({ businessId });
			get().setState('advertisements', data.data?.ads);
			return data.data?.ads
		},
		setState: (key, value) => {
			set((s) => ({ [key]: value }));
		},
		addAdvertisement: (value) => {
			set((s) => {
				const newAdvertisements = [...s.advertisements];
				newAdvertisements.push(value);

				return {
					advertisements: newAdvertisements,
				};
			});
		},
		updateAdvertisement: (id, value) => {
			set((s) => {
				const newAdvertisements = s.advertisements.map((x, i) => {
					if (+x.id! == +id) {
						return {
							...x,
							...value,
						};
					}
					return x;
				});
				return { advertisements: newAdvertisements };
			});
		},
		deleteAdvertisement: (id) => {
			const newAdvertisements = get().advertisements.filter((x) => x.id != id);
			get().setState('advertisements', newAdvertisements);
		},
		getAdvertisement: (id) => {
			return get().advertisements.find((x) => x.id == id) || {};
		},
		resetState: () => {
			set((s) => initialState);
		},
	})
	//         {
	//             name: STORE_KEY,
	//             storage: createJSONStorage(() => localStorage),
	//             version: 1,
	//         }
	//     )
	// )
);
export default useAdvertismentsStore;
