export const isObject = (objValue = {}) => {
	return objValue && typeof objValue === 'object' && objValue.constructor === Object;
};

export const isObjectEmpty = (obj = {}) => {
	if (obj == null || obj == undefined) return true;
	if (Array.isArray(obj)) return obj.length === 0;
	if (typeof obj === 'object' && obj !== null && !Array.isArray(obj)) {
		return Object.keys(obj).length === 0;
	}
	return false;
};

export const removeDuplicateByKey = (arr = [], key = '') => {
	const values = {};
	return arr.filter(function (item) {
		const val = item[key];
		const exists = values[val];
		values[val] = true;
		return !exists;
	});
};

export const updateObject = (object = {}, path = '', value = {}) => {
	const obj = JSON.parse(JSON.stringify(object));

	path.split('.').reduce((o, p, i) => {
		if (path.split('.').length === ++i) {
			if (Array.isArray(o[p])) {
				if (Array.isArray(value)) {
					o[p] = Array.from(new Set([...o[p], ...value]));
				} else {
					o[p] = Array.from(new Set([...o[p], value]));
				}
			} else {
				if (Array.isArray(value)) {
					o[p] = Array.from(new Set([...o[p], ...value]));
				} else if (isObject(value)) {
					o[p] = {
						...o[p],
						...value,
					};
				} else {
					o[p] = value;
				}
			}
		}
		return o[p] || {};
	}, obj);
	return obj;
};
