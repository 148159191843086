import { isObject, updateObject } from 'utils/object';

const coreReducer = (
	state: {
		[key: string | symbol | number]: any;
	},
	action: {
		type:
			| 'SET_STATE'
			| 'UPDATE_STATE'
			| 'SET_NEW_STATE'
			| 'RESET_STATE'
			| 'ADD_STATE';
		key?: string;
		payload: {
			value?: any;
			initialState?: any;
		};
	}
) => {
	const { type, key, payload } = action;
	switch (type) {
		case 'SET_NEW_STATE':
			return payload.value;
			break;
		case 'UPDATE_STATE':
		case 'SET_STATE':
			const obj = updateObject(state, key, payload.value);
			return {
				...state,
				...obj,
			};
			break;
		case 'ADD_STATE':
			return {
				...state,
				[`${key}`]: payload.value,
			};
			break;
		case 'RESET_STATE':
			return {
				...payload.initialState,
			};
			break;
	}

	throw new Error('no matching action type');
};

export default coreReducer;
