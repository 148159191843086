import { API } from 'utils/axios';

export const getAdvertisements = async ({
	businessId
}) => {
	const command = 'handle-advertisement';
	const data = {
		cmd: command,
		action_mode: "get",
		business_id: businessId,
	};

	return await API({ data });
};
export const createAdvertisement = async ({
	image,
	title,
	description,
	buttonTitle,
	isEnabled,
	advertisementURL,
	businessId
}) => {
	const command = 'handle-advertisement';
	const data = {
		cmd: command,
		image,
		action_mode: "create",
		form_id: "dbofields",
		obj_id: -1,
		business_id: businessId,
		dbofields: {
			title,
			description,
			action_title: buttonTitle,
			is_enabled: isEnabled,
			action_url: advertisementURL
		}
	};

	return await API({ data }).then(res => {
		console.log("Res", res)
		return res
	});
};

export const updateAdvertisement = async ({
	image,
	title,
	description,
	buttonTitle,
	isEnabled,
	advertisementURL,
	businessId,
	id,
}) => {
	const command = 'handle-advertisement';
	const data = {
		cmd: command,
		image,
		action_mode: "update",
		form_id: "dbofields",
		obj_id: id,
		business_id: businessId,
		dbofields: {
			title,
			description,
			action_title: buttonTitle,
			is_enabled: isEnabled,
			action_url: advertisementURL
		},
	};
	return await API({ data });
};

export const daleteAdvertisement = async ({
	id,
}) => {
	const command = 'handle-advertisement';
	const data = {
		cmd: command,
		action_mode: "delete",
		obj_id: id,
	};
	return await API({ data });
};
export const addToEvent = async ({
	eventId, advertisementId,
}) => {
	const command = 'handle-advertisement';
	const data = {
		cmd: command,
		action_mode: "add-to-event",
		advertisement_id: advertisementId,
		event_id: eventId,
	};
	return await API({ data });
};
export const removeFromeEvent = async ({
	eventId, advertisementId,
}) => {
	const command = 'handle-advertisement';
	const data = {
		cmd: command,
		action_mode: "remove-from-event",
		advertisement_id: advertisementId,
		event_id: eventId,
	};
	return await API({ data });
};
