import React, { startTransition } from 'react'
import { useNavigate } from "react-router-dom";

type PropsType = {
    startTransition?: boolean
}
const propsDefaultValues = {
    startTransition: true
}
function useAppNavigate() {
    const navigate = useNavigate()

    const appNavigate = (url: string, props: PropsType = propsDefaultValues) => {
        if (props.startTransition) {
            startTransition(() => {
                navigate(url)
            })
            return
        }
        navigate(url)
    }
    return appNavigate
}
export default useAppNavigate